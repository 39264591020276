.right {
  float: right;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 200px;
  max-width:100%;
  overflow: hidden;
  padding: 15px;
}
