@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i&display=swap');
@import "node_modules/normalize-scss/sass/_normalize.scss";
html{
  background-color: #fff;
}

.ant-avatar {
  margin-right: 8px;
}

.ant-dropdown-trigger{
  cursor: pointer;
}

.ant-pro-global-header{
  span, a{
    padding-right: 15px; 
  }
}

.page-name{
  max-width: 270px;
  display: block;
}

.thumbnail-holder{
  width: 250px;
}
.thumbnail{
  display: block;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #F4F5F6;
}

.list{
  list-style: none;
  li{
    margin-bottom: 10px;
  }
}
.react-confirm-alert-overlay{
  z-index: 999999 !important;
}

.btn-success{
  background-color:#9ac355;
  border: none;
}
.export-table{
  display: none;
}

.btn-gp button{
  margin: 5px;
}

.pad-container{
  padding: 15px;
  min-width: 200px;
}
.disabled-content{
  color:rgba(0, 0, 0, 0.2) !important;
  h2, a{
    color:rgba(0, 0, 0, 0.2) !important;
  }
}

.note{
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
}
.chart-container{
  width:100%;
  text-align:center;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hideTableElements{
  display: none;
}
.ant-advanced-search-form{
  padding: 15px !important;
}
.commonActionsWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  .btnItem{

    width: 100%;
    margin: 3px;
  }
}

.tags-list{
  list-style: none;
  padding: 0;
  li{
    padding: 3px;
    margin-left: 0;
    padding-left: 0;
    display: inline-block;
  }
}

.stepContainer{
  padding: 15px;
  min-height: 200px;
}

.hideLabel{
  .ant-form-item-label{
    visibility: hidden;
  }
}

.config-form{
  margin-top: 15px !important;
}

.confirmScrollable {
  max-height: 300px;
  overflow: scroll;
  border: solid 1px;
}
.ant-list-grid .ant-list-pagination {
  padding-bottom: 20px;
}

.ellipsis{
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-confirm-alert-body button[disabled=disabled], button:disabled{
  background-color: #eee !important;
  color: rgb(182, 185, 188) !important;
  cursor:no-drop !important;;

}

.ant-message{
  z-index: 99999999;
}

.ant-btn-success{
  color: #fff;
  background-color:#9ac355;
  border-color:#9ac355;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.btn-margin{
  margin-left: 3px;
  margin-right: 3px;
}

.react-confirm-alert-body{
  ul{
    max-height: 100px;
    overflow: scroll;
    border: 1px solid;
    border-color: #eee;
    border-radius: 8px;
  }
}

#shareSearch, #clearAllFilters{
  margin-left: 5px;
  margin-right: 5px;
}
.fire{
  background-color: #a40606 !important;
  background-image: linear-gradient(315deg, #a40606 0%, #d98324 74%) !important;

}